import React, { useCallback, useState } from "react";
import { useRef } from "react";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
// import NavBar from "../components/NavBar";
import SideBar from "../components/SideBar";
// import { ModalsProvider } from "@mantine/modals";

const Template = ({ children, navBar, sideBar }) => {
  const [openedMenu, setOpenedMenu] = useState(false);
  const [minViewPort] = useState(false);

  const sidebarRef = useRef(null);

  const handleResize = useCallback(() => {
    setOpenedMenu((v) => !v);
  }, []);

  // useMemo(() => {
  //   // console.log("width: " + width);
  //   if (width <= 900) {
  //     setMinViewPort(true);
  //     setOpenedMenu(true);
  //   } else {
  //     setMinViewPort(false);
  //     setOpenedMenu(false);
  //   }
  // }, [width]);

  return (
    <div className="interface-container">
      <div className="body-container">
        <div
          className={"sidebar-container"}
          style={{
            width: !openedMenu ? 230 : 60,
          }}
          ref={sidebarRef}
        >
          <div className="menu-controller" onClick={handleResize}>
            {openedMenu ? (
              <AiOutlineArrowRight className="menu-controller-icon" />
            ) : (
              <AiOutlineArrowLeft className="menu-controller-icon" />
            )}
          </div>
          {/* ************** sidebar **************** */}
          <SideBar openedMenu={openedMenu} />
        </div>
        <div
          className="children-container"
          style={{
            padding: openedMenu
              ? "0px 0px 0 55px"
              : minViewPort
              ? "0px 0px 0 55px"
              : "0px 0px 0 230px",
          }}
        >
          {/* <NavBar /> */}
          <div className="px-4 mt-3 position-relative">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default Template;
